import React from "react";
import styled from "styled-components";
import logoR from "../_assets/image/logo-r.png";
const LoadingR = ({ fullScreen, isShow }) => {
    return (
        <LoadingContainer fullScreen={fullScreen ? fullScreen : false} isShow={isShow}>
            <div className="center">
                <div className="ring"></div>
                <span className="image">
                    <img src={logoR} alt="logo" />
                </span>
            </div>
        </LoadingContainer>
    );
};

const LoadingContainer = styled.div`
    width: ${({ fullScreen }) => (fullScreen ? "100%" : "auto")};
    background: ${({ fullScreen }) => (fullScreen ? "rgba(0,0,0,29%)" : "transparent")};
    display: ${({ isShow }) => (isShow ? "block" : "none")};
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: ${({ fullScreen }) => (fullScreen ? "100vh" : "100%")};
    }
    .ring {
        position: absolute;
        width: ${({ fullScreen }) => (fullScreen ? "155px" : "50px")};
        height: ${({ fullScreen }) => (fullScreen ? "155px" : "50px")};
        border-radius: 50%;
    }
    .ring:before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        box-shadow: 0 0 ${({ fullScreen }) => (fullScreen ? "5px" : "3px")} rgba(255, 255, 255, 0.3);
        animation: ring 2s linear infinite;
    }
    .image img {
        animation: image-hide 3s infinite;
    }
    .image {
        img {
            width: ${({ fullScreen }) => (fullScreen ? "65px" : "25px")};
            height: ${({ fullScreen }) => (fullScreen ? "65px" : "25px")};
        }
    }
    @keyframes ring {
        0% {
            transform: rotate(0deg);
            box-shadow: 1px ${({ fullScreen }) => (fullScreen ? "5px" : "3px")} 2px #e65c00;
        }
        50% {
            transform: rotate(180deg);
            box-shadow: 1px ${({ fullScreen }) => (fullScreen ? "5px" : "3px")} 2px #18b201;
        }
        100% {
            transform: rotate(360deg);
            box-shadow: 1px ${({ fullScreen }) => (fullScreen ? "5px" : "3px")} 2px #e65c00;
        }
    }
    @keyframes image-hide {
        0% {
            display: block;
        }
        50% {
            display: none;
        }
        100% {
            display: block;
        }
    }
`;

export default LoadingR;

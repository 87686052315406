import React, { useEffect } from "react";
import styled from "styled-components";

const ToastMessage = ({show , onClose, message, type}) => {
    useEffect(()=>{
        if(show) {
            setTimeout(()=>{
                onClose();
            },[2500])
        }
    },[show])
    

    return (
        <ToastMessageStyle bg={type === "ERROR" ? "#FFDFDF" : type==="WARNING" ? "#f1e3b9" : "#DFFFE0"} color={type === "ERROR" ? "#D70000" : type==="WARNING" ? "#fbbc04" : "#28B259"} className={show ? 'show':""}>
            <div>
                {message}
            </div>
            <div onClick={()=> {
                onClose()
            }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 1L1 14.5" stroke="black" stroke-width="2" stroke-linecap="round"/>
                <path d="M14.5 14.5L1 0.999999" stroke="black" stroke-width="2" stroke-linecap="round"/>
                </svg>

            </div>

        </ToastMessageStyle>
    );
}
    


const ToastMessageStyle = styled.div`
    position: fixed;
    width: 100%;
    max-width: 500px;
    top: 0;
    z-index: 66666;
    right: 0;
    box-sizing: border-box;
    border-radius: 0 0 12px 12px;
    background: ${props => props.bg};
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 12px;
    transform: translateY(-100%);
    transition: all 0.5s ease;

    color: ${props => props.color};
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    
    

    &.show{
        transform: translateY(0);
    }

    

}

`
export default ToastMessage;

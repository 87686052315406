import BaseEcomServices from "./BaseEcomServices";

const StampSizeServices = {
    GetShipmentActive: async () => {
        return await BaseEcomServices.Post(`/api/Garment/get-shipment-active`);
    },
    GetShipmentPrintBatchById: async (id) => {
        return await BaseEcomServices.Get(`/api/Garment/get-shipment-print-batch-byId/${id}`);
    },
    GetShipmentPrintBatchByShipmentId: async (id) => {
        return await BaseEcomServices.Get(`/api/Garment/get-shipment-print-batch-by-shipmentId/${id}`);
    },
    CreateShipmenntPrintBatch: async (model) => {
        return await BaseEcomServices.Post(`/api/Garment/create-shipment-print-batch`, model);
    },
    GetModelAddPrintBatch: async (shipmentId) => {
        return await BaseEcomServices.Get(`/api/Garment/get-model-add-print-batch/${shipmentId}`);
    }
};

export default StampSizeServices;

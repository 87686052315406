import React, {useContext , useEffect , useState} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import LogoRanus from "../../_assets/image/LogoRanus.png"
import StampSizeServices from "../../based/services/StampSizeServices";
import LoadingR from "../../based/LoadingR"
import Common from "../../based/Common";
import ToastMessage from "../components/ToastMessage";
import ModalLogout from "../components/ModalLogout";
import JSZip from 'jszip';


const Home = (props) => {
    const [data , setData] = useState([])
    const [dataActive, setDataActive] = useState({})
    const [isBusy , setBusy] = useState(false)
    const [printBatch , setPrintBatch] = useState([])
    const [isShowPrintBatch , setShowPrintBatch] = useState(false)
    const [printBatchItem , setPrintBatchItem] = useState({})
    const [isShowFormPrintBatch, setIsShowFormPrintBatch] = useState(false)
    const [indexItem , setIndexItem] = useState(0)
    const [showToast , setShowToast] = useState({
        isShow: false , message: "" , type: ""
    })
    const [isShowLogout , setShowLogout] = useState(false)


    useEffect(()=>{
        getData()
    },[])

    const getData = async () => {
        setBusy(true)
        let [err , data] = await StampSizeServices.GetShipmentActive()
        if(!err && data){
            setData(data)
        }else{
            setData([])
        }
        setBusy(false)
    }


    const getPrintBatch = async (id) => {
        setBusy(true)
        let [err , data] = await StampSizeServices.GetShipmentPrintBatchByShipmentId(id)
        if(!err && data){
            setPrintBatch(data)
        }else{
            setPrintBatch([])
        }
        setBusy(false)
    }


    const handleGetPrintBatch = async (id, idx) => {
        setBusy(true)
        let [err , data] = await StampSizeServices.GetShipmentPrintBatchById(id)
        if(!err && data){
            setIsShowFormPrintBatch(true);
            setPrintBatchItem(data)
            setIndexItem(idx)
        }else{
            setPrintBatchItem({})
        }
        setBusy(false)
    }
    const handleAddPrintBatch = async () => {
        setBusy(true)
        let [err , data] = await StampSizeServices.GetModelAddPrintBatch(dataActive.id)
        if(!err && data){
            setPrintBatchItem(data)
            setIsShowFormPrintBatch(true);
            setIndexItem(printBatch.length + 1)
        }else{
            setPrintBatchItem({})
        }
        setBusy(false)
    }
    const handleAdd = async (item) => {
        setBusy(true)
        let [err , data] = await StampSizeServices.CreateShipmenntPrintBatch(item)
        if(!err && data){
            setIsShowFormPrintBatch(false);
            getPrintBatch(dataActive.id)
        }else{
            setShowToast({
                isShow: true, message: "Xử lý lỗi", type: "ERROR"
            })
        }
        setBusy(false)
    }


    return (
        <HomeStyle>
            <ToastMessage
                show={showToast.isShow} 
                message={showToast.message} 
                type={showToast.type}
                onClose={()=>{
                    setShowToast({
                        isShow: false , message: "" , type: ""
                    })
                }}
            />
            <ModalLogout isShow={isShowLogout} onClose={()=>{setShowLogout(false)}} />
            <LoadingR isShow={isBusy} fullScreen={true} />
            <div className="box-logo">
                <img src={LogoRanus} />
                <span className="add-printbatch"
                    onClick={()=>{
                        setShowLogout(true)
                    }}
                >Đăng xuất</span>
            </div>
            <div className="container">
                {isShowPrintBatch ? 
                <div className="container-table w-500">
                    <div className="box-table">
                    <div className="box-header">
                        
                        <h4>
                            <strong onClick={()=>{
                                setIsShowFormPrintBatch(false);
                                setShowPrintBatch(false)
                            }}>
                                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 16L2.41421 10.4142C1.63316 9.63316 1.63316 8.36683 2.41421 7.58579L8 2" stroke="#444444" stroke-width="3" stroke-linecap="round"/>
                                </svg>
                            </strong>
                            <span>
                            Danh sách đã in lô {dataActive.name}</span></h4>
                    </div>
                    <table>
                            <thead>
                                <tr>
                                    <td style={{borderRadius: '12px 0 0 0'}}>Đợt</td>
                                    <td>Ngày xuất file</td>
                                    <td>Tổng số tem</td>
                                    <td style={{borderRadius: '0 12px 0 0'}}>Xuất file</td>
                                </tr>
                            </thead>
                            <tbody>
                                {printBatch.length > 0 && printBatch.map((item, idx)=>{
                                    return  <tr className={`${item.status == 2 ? "success" : item.status == 3 ? "error" : "" }`} key={idx}
                                        onClick={()=>{
                                            handleGetPrintBatch(item.id, idx)
                                            
                                        }}
                                    >
                                    <td>Đợt{" "}{idx + 1}</td>
                                    <td>{Common.formatDate(new Date(item.dateExportFile))}</td>
                                    <td>{item.countStamp}</td>
                                    <td className={`${item.status == 2 ? "text-success" : item.status == 3 ? "text-error" : "text-warning" }`}>
                                        {
                                            item.status == 2 && "Đã xong" ||
                                            item.status == 0 && "Đang đợi xử lý" ||
                                            item.status == 1 && "Đang xử lý" ||
                                            item.status == 3 && "Xử lý lỗi" ||
                                            item.status == 4 && "Đang chờ xử lý"
                                        }
                                    </td>
                                </tr>
                                })}
                                
                            </tbody>
                    </table>
                    <span className="add-printbatch"
                        onClick={()=>{
                            handleAddPrintBatch()
                        }}
                    >Thêm đợt +</span>
                    </div>
                </div>
            :
                <div className="container-table w-500">
                    <div className="box-table">
                    <div className="box-header">
                        <h4>Danh sách các lô</h4>
                    </div>
                    <table>
                            <thead>
                                <tr>
                                    <td style={{borderRadius: '12px 0 0 0'}}>Số lô sản xuất</td>
                                    <td>Ngày yêu cầu</td>
                                    <td style={{borderRadius: '0 12px 0 0'}}>Số file đã xuất</td>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 && data.map((item, idx)=>{
                                    return  <tr key={idx}
                                        onClick={()=>{
                                            setDataActive(item)
                                            setShowPrintBatch(true)
                                            getPrintBatch(item.id)
                                        }}
                                    >
                                    <td>Lô{" "}{item.name}</td>
                                    <td>{Common.formatDate(new Date(item.requestDate))}</td>
                                    <td>{item.countFile}</td>
                                </tr>
                                })}
                                
                            </tbody>
                    </table>
                    </div>
                </div>}
                <div className="container-table w-570">
                    {(isShowPrintBatch && isShowFormPrintBatch ) ?
                     <PrintBatchItem data={printBatchItem} setShowToast={setShowToast}
                      handleAdd={handleAdd} idx={indexItem}/> : 
                    <div className="box-table mh-300">
                        Vui lòng chọn lô và đợt in
                    </div>}
                </div>
            </div>
        </HomeStyle>
    );
};


const PrintBatchItem = ({data, idx, handleAdd, setShowToast}) => {
    const [item, setItem] = useState({})
    const [isAdd , setIsAdd] = useState(false)

    useEffect(()=>{
        setItem(data)
        if(data.id > 0){
            setIsAdd(false)
        }else{
            setIsAdd(true)
        }
    },[data])

    const handleFillAll = () => {
        let newDT = {...item}
        
        newDT.products.forEach((product)=>{
            product.sizeStamps.forEach((tem)=>{
                tem.printQuantity = tem.quantity
            })
        })

        setItem(newDT)
    }

    const handleUnFillAll = () => {
        let newDT = {...item}
        
        newDT.products.forEach((product)=>{
            product.sizeStamps.forEach((tem)=>{
                tem.printQuantity = "";
            })
        })

        setItem(newDT)
    }   

    const isCheck = async () => {
        let check = true
        item.products.forEach((product)=>{
            if(product.sizeStamps.some(i => (!i.printQuantity && i.printQuantity != 0) || i.printQuantity < 0 )){
                check = false
            }
                
        })
        return check
    }

    const handleDownloadStamp = async (data)=>{

        if(data.fileUrl){
            window.open(data.fileUrl, "_blank")
            
          
        }
            
    
         
    
    }

    const handleDownloadFileSize = async (files, nameFile) => {
        const zip = new JSZip();
        const imagePromises = [];

        files.forEach((image, index) => {
        const { fileUrl, id } = image;

        if (fileUrl) {
            const fileName = `${nameFile + "-" + id}.png`;
            // Tải hình ảnh từ URL và thêm vào thư mục trong ZIP
            const imagePromise = fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
                zip.file(fileName, blob);
            })
            .catch((error) => {
                console.error(`Lỗi khi tải hình ảnh từ ${fileUrl}: ${error}`);
            });

            imagePromises.push(imagePromise);
        }
        });

        // Khi tất cả các hình ảnh đã được tải xong, tạo tệp ZIP và tải về
        Promise.all(imagePromises).then(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const url = window.URL.createObjectURL(content);
            const a = document.createElement('a');
            a.href = url;
            a.download = nameFile +'.zip';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        });
        });
      };

     
    return <div className="box-table">
                <div className="box-header">
                        <h4>{isAdd ? `Thêm đợt ${idx}` : `Chi tiết đợt ${idx + 1}`}</h4>
                        {isAdd && <span className="error"
                            onClick={()=>{
                                handleUnFillAll()
                            }}
                        >Xóa hết</span>}
                        {isAdd && <span className="success"
                            onClick={()=>{
                                handleFillAll()
                            }}
                        >Tự động điền</span>}
                        <div className="box-input">
                            <span>Dự phòng</span>
                            <input type="number" disabled={isAdd ? "" : "disabled"} value={item.percentReserve} 
                                onChange={(e)=>{
                                    setItem({...item, percentReserve: e.target.value})
                                }}
                            
                            />
                            <span>%</span>
                        </div>
                </div>
                {!isAdd && <div className={`box-header-bottom ${item.status == 2 ? "text-success" : item.status == 3 ? "text-error" : "text-warning" }`}>
                    {
                        item.status == 2 && "Đã xong" ||
                        item.status == 0 && "Đang đợi xử lý" ||
                        item.status == 1 && "Đang xử lý" ||
                        item.status == 3 && "Xử lý lỗi" ||
                        item.status == 4 && "Đang chờ xử lý"
                    }
                </div>}
                {item.products && item.products.length > 0 &&
                item.products.map((product , index)=>{
                    return <table key={index}>
                                <thead>
                                    <tr>
                                        <td style={{borderRadius: '12px 0 0 0'}}>{product.productName}</td>
                                        <td></td>
                                        {isAdd && <td className="no-wrap">Yêu cầu</td>}
                                        <td className="no-wrap">{isAdd ? "Cần in" : "Đã in"}</td>
                                        <td style={{borderRadius: '0 12px 0 0'}}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {product.sizeStamps && product.sizeStamps.length > 0 &&
                                    product.sizeStamps.map((stamp , i)=>{
                                        return <tr key={i}>
                                                    <td className="flex-column-td">
                                                        <span>{stamp.colorName}</span>
                                                        <div style={{width: 68, height: 28, borderRadius: '10px',
                                                        border: '1px solid #D8D8D8', background: stamp.colorCode
                                                    }}></div>
                                                    </td>
                                                    <td style={{width: 80, textAlign: 'center'}}>{stamp.sizeName}</td>
                                                    {isAdd && <td style={{width: 80, textAlign: 'center'}}>{stamp.quantity}</td>}
                                                    <td style={{width: 80, textAlign: 'center'}}>
                                                        {isAdd ? <input type="number" placeholder="Nhập" value={stamp.printQuantity} onChange={(e)=>{
                                                            let newDT = {...item}
                                                            newDT.products[index].sizeStamps[i].printQuantity = e.target.value
                                                            setItem(newDT)
                                                        }} /> : stamp.printQuantity}
                                                    </td>
                                                    <td style={{width: 42, textAlign: 'center'}} 
                                                        onClick={()=>{
                                                            if(!isAdd && item.status == 2){
                                                                if(stamp.files.length > 0){
                                                                    let nameFile =product.productName + "-" +  stamp.colorName + '-' + stamp.sizeName
                                                                    handleDownloadFileSize(stamp.files, nameFile)
                                                                }else{
                                                                    setShowToast({
                                                                        isShow: true, message: "Không tồn tại file", type: "WARNING"
                                                                    })
                                                                }
                                                            }else{
                                                                setShowToast({
                                                                    isShow: true, message: "Vui lòng đợi hoàn thành", type: "WARNING"
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9 11.25V2.25" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </td>
                                                </tr>
                                    })}
                                </tbody>
                            </table>
                })}
                <div className="box-header mt-3">
                        <div className="box-header" style={{justifyContent: 'center'}}
                            onClick={()=>{
                                if(item.status == 2 && item.stampCovers.length > 0) {
                                    handleDownloadStamp(item.stampCovers[0])
                                }else{
                                    setShowToast({
                                        isShow: true, message: "Vui lòng đợi hoàn thành", type: "WARNING"
                                    })
                                }
                            }}
                        
                        >
                            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 19L8 14L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V19Z" stroke="#28B259" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span className="ml-2" style={{color: '#28B259'}}>In tem dán</span>
                        </div>
                        {isAdd ? <button className="btn-outfile" onClick={ async ()=>{
                        var check = await isCheck()
                        if(check){
                            handleAdd(item)
                        }else{
                            setShowToast({
                                isShow: true, message: "Vui lòng điền đầy đủ thông tin", type: "WARNING"
                            })
                        }
                    }}>Xuất file in</button>:
                    <button className="btn-outfile" onClick={ async ()=>{
                        if(item.status == 2 && item.files.length > 0) {
                            let nameFile = "filein-"+item.id
                            handleDownloadFileSize(item.files, nameFile)
                        }else{
                            setShowToast({
                                isShow: true, message: "Vui lòng đợi hoàn thành", type: "WARNING"
                            })
                        }
                    }}>Tải file in</button>
                    }
                </div>

            </div>
}
const HomeStyle = styled.div`
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    min-height: 100svh;
    font-family: 'Lexend' ;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #F3F3F3;

    .add-printbatch{
        color: #28B259;
        font-family: Lexend;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
        cursor: pointer;
        margin-top: 10px;
    }
    .container{
        margin: 0 auto;
        display: flex;

    }
    .container-table{
        &.w-570{
            width: 570px;
        }

        &.w-500{
            width: 500px;
            margin-right: 20px;
        }
        

        .btn-outfile{
            border-radius: 10px;
            background: #28B259;
            width: 125px;
            height: 42px;
            line-height: 42px;
            color: #FFF;
            font-family: Lexend;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            text-align: center;
            border: none;
            outline: none;
           
        }


        .box-table{
            border-radius: 20px;
            background: #FFF;
            padding: 25px;
            margin-top: 30px;

            &.mh-300{
                min-height: 300px;
            }
            .box-header-bottom{
                margin-top: 10px;
                text-align: right;

                &.text-success{
                    color: #28B259;
                }
                &.text-error{
                    color: #F96A74;
                }

                &.text-warning{
                    color: #13AAFF;
                }

            }
    
            .box-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                h4{
                    color: #444;
                    font-family: Lexend;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 83.333% */
                    margin: 0;

                    display: flex;
                    align-items: center;

                    strong{
                        margin-right: 10px;
                        cursor: pointer;
                    }
                    span{
                        color: #444;
                        font-family: Lexend;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px; /* 83.333% */
                        margin: 0;
                    }
                }
    
                span{
                    cursor: pointer;
                    font-family: Lexend;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 142.857% */
    
                    &.error{
                        color: #F96A74;
    
                    }
                    &.success{
                        color: #28B259;
    
                    }
    
                    &.mg-2{
                        margin: 0 10px;
                    }
                }
    
                .box-input{
                    border-radius: 12px;
                    border: 2px solid #D8D8D8;
                    background: #FFF;
                    display: flex;
                    padding: 7px 10px;
                    align-items: center;
                    

                    input{
                        border: none;
                        outline: none;
                        padding: 0 5px;
                        width: 30px;
                        text-align: center;

                        ::-webkit-outer-spin-button,
                        ::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                    }
    
                    span{
                        color: #444;
                        font-family: Lexend;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 142.857% */
                    }
                }
            }
    
    
            table{
                margin-top: 20px;
                border-radius: 12px;
                width: 100%;
                
                thead {
                    background: #D1D1D1;
    
                    tr{
    
                        td{
                            padding: 13px;
                            background: #D1D1D1;
                            color: #444;
                            font-family: Lexend;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            
                            &.no-wrap{
                                white-space: nowrap;
                            }
                        }
                    }
                }
    
                tbody{
                    tr{
                        cursor: pointer;

                        &.success{
                            background: #DFFFE0;
                            .text-success{
                                color: #28B259;
                            }
                        }
                        &.error{
                            background: #FFDFDF;
                            .text-error{
                                color: #F96A74;
                            }
                        }

                        .text-warning{
                            color: #13AAFF;
                        }
                        td{
                            color: #444;
                            font-family: Lexend;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            border: 1px solid #D8D8D8;
                            padding: 7px 10px;

                            input{
                                border: none;
                                outline: none;
                                padding: 0 5px;
                                width: 100%;
                                text-align: center;
                                height: 100%;
                                ::-webkit-outer-spin-button,
                                ::-webkit-inner-spin-button {
                                    -webkit-appearance: none;
                                    margin: 0;
                                }
                                ::placeholder{
                                    opacity: 0.2;
                                }
                            }
    
    
    
                            &.flex-column-td{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            }
                            
                        }
    
                        :last-child{
    
                            td{
                                :first-child{
                                    border-radius: 0 0 0 12px;
                                }
    
                                :last-child{
                                    border-radius: 0 0 12px 0; 
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .box-logo{
        width: 100%;
        text-align: center;
        margin: 0 auto;
        img{
            width: auto;
            height: auto;
        }

        span{
            margin-left: 300px;
        }
        
    }

    





`
export const BasedModalStyle = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, 0.7);
    display: grid;
    place-items: center;
    transition: all 0.3s ease-out;

    .search{
        margin-top: 0px;
    }


    &.modal_center{
        visibility: hidden;
        opacity: 0;
        pointer-events: none;

        &.show_modal{
            visibility: visible;
            opacity: 1;
            background-color: rgba(0,0,0, 0.7);
            pointer-events: auto;


            .partner_modal{
                transform: scale(1);
                opacity: 1;
                pointer-events: auto;
            }
            
        }

        .partner_modal{
            transform: scale(0);
            opacity: 0;
            pointer-events: none;
            box-shadow: 0px 0px 31px 7px rgba(0,0,0,0.4);
        }
    }

    &.relative_modal{
        background-color: transparent;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;

        &.show_modal{
            visibility: visible;
            opacity: 1;
            pointer-events: auto;


            .partner_modal{
                transform: scale(1);
                opacity: 1;
                pointer-events: auto;
                box-shadow: 0px 0px 31px 7px rgba(0,0,0,0.4);
            }
            
        }

        .partner_modal{
            position: absolute;
            transform: scale(0);
            opacity: 0;
            pointer-events: none;
        }
    }


    &.bottom_modal{
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        background-color: rgba(0,0,0, 0.7) !important;

        &.show_modal{
            visibility: visible;
            opacity: 1;
            background-color: transparent;
            pointer-events: auto;


            .partner_modal{
                transform: translateY(0);
                opacity: 1;
                pointer-events: auto;
            }
            
        }

        .partner_modal{
            transform: translateY(100%);
            opacity: 0;
            pointer-events: none;
        }
    }

    .partner_modal{
        padding: 16px;
        border-radius: 10px;
        background-color: #fff;
        transition: all 0.3s ease-out;





        .partner_modal_header{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .partner_modal_header-title{
                color: #505050;
                font-family: Lexend;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                span{
                    line-height: 1;
                }
            }
            .partner_modal_close{
                cursor: pointer;
                line-height: 1;
            }
        }

        .partner_modal_body{
            overflow-y: auto;
            height: -webkit-fill-available;
            scroll-behavior: smooth;
            will-change: transform;
            transition: all 0.3s ease-out;

            ::-webkit-scrollbar{
                display: none;
            }
            
            .partner_modal_body-desc{
                margin-top: 4px;
                color: #A0A0A0;
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
            }   
        }



    }

`

export default Home;






